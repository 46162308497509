import React, { Component } from 'react';
import StaticMapComponent from './components/static-map';
import MapComponent from './components/map';


class App extends Component {
  constructor(props) {
    super(props)
    
    
    this.state = {
      all: true,
      points: [],
      point: {},
      city: null,
    }
  }

  componentDidMount() {
    document.addEventListener("message", this.handleMessage);
  }

  handleMessage = (event) => {
    const params = JSON.parse(event.data);
    this.setState({
      all: params.all || false, 
      points: params.points || [],
      point: params.point || {},
      city: params.city || null,
    })
  }

  render() {
    const {points, point, city, all} = this.state;

    return (
      <React.Fragment>
      { 
        all ? (
            <MapComponent points={points} city={city}/>
          ) : (
            <StaticMapComponent point={point}/>
          )
      }
      </React.Fragment>
    );
  }
}

export default App;
