import React, { Component } from 'react';
import '../App.css';
import {YMaps, Map} from 'react-yandex-maps';
import PointComponent from './point';
import $ from 'jquery';


class MapComponent extends Component {
  constructor(props) {
    super(props);

    this.ymaps = null;
    this.mapRef = null;
    this.balloon = null;

    this.geoCode = this.geoCode.bind(this);
    this.initMap = this.initMap.bind(this);
    this.onReadyMap = this.onReadyMap.bind(this);
    this.addBalloon = this.addBalloon.bind(this);

    this.handlePoint = this.handlePoint.bind(this);
    this.handleDetails = this.handleDetails.bind(this);
    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      points: [],
      mapState: { 
        center: [55.76, 37.64], 
        zoom: 10, 
        controls: ['zoomControl'] 
      },
    }
  }

  handleDetails(point) {
    window.postMessage(JSON.stringify({name: 'details', point}), "*");
  }

  handleSelect(point) {
    window.postMessage(JSON.stringify({name: 'select', point}), "*");
  }

  addBalloon(point) {

    const self = this;

    if (this.balloon) {
      this.mapRef.controls.remove(this.balloon)
    }

    const CustomControlClass = function (options) {
      CustomControlClass.superclass.constructor.call(this, options);
      this._$content = null;
      this._$point = point;
    };

    this.ymaps.util.augment(CustomControlClass, this.ymaps.collection.Item, {

      onAddToMap: function (map) {
        CustomControlClass.superclass.onAddToMap.call(this, map);
        this.getParent().getChildElement(this).then(this._onGetChildElement, this);
      },

      onRemoveFromMap: function (oldMap) {
        if (this._$content) {
          this._$content.remove();
        }
        CustomControlClass.superclass.onRemoveFromMap.call(this, oldMap);
      },

      _onGetChildElement: function (parentDomContainer) {
        const template = `
          <div class="point-container">
            <div class="info-block">
              <div class="name">${this._$point.name}</div>
              <div class="address">Стоимость доставки: ${this._$point.cost} ₽</div>
            </div>
            <div class="btn-block">
              <button id="details">ПОДРОБНЕЕ</button>
              <button id="select">ВЫБРАТЬ</button>
            </div>
          </div>
        `;

        this._$content = $(template).appendTo(parentDomContainer);
        $('#details').bind('click', () => this.showPoint());
        $('#select').bind('click', () => this.selectPoint());
      },

      showPoint() {
        self.handleDetails(this._$point)
      },

      selectPoint() {
        self.handleSelect(this._$point)
      }
    });

    this.balloon = new CustomControlClass();
    this.mapRef.controls.add(this.balloon, {
      // float: 'none',
      position: {
        bottom: 0,
        left: 0,
      }
    });

  }

  initMap(ref) {
    this.mapRef = ref;

    // var zoomControl = new this.ymaps.control.ZoomControl({options: {size: "small"}});
    // this.mapRef.controls.add(zoomControl);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.city !== this.props.city) {
      this.geoCode(this.props.city)
    }
  }

  onReadyMap(ymaps) {
    const {city} = this.props;

    this.ymaps = ymaps;

    if (city) {
      this.geoCode(city);
    }
  }

  geoCode(query) {
    this.ymaps.geocode(query, {json: true})
    .then(res => {

      const results = res.GeoObjectCollection.featureMember;
      const geoObject = results[0].GeoObject;
      const coords = geoObject.Point.pos.split(" ");

      this.mapRef.setCenter([coords[1], coords[0]]);
    })
  }

  handlePoint(point) {
    this.addBalloon(point)
  }

  render() {
    const {mapState} = this.state;
    const {points} = this.props;

    return (
      <div className="container">
        <YMaps onApiAvaliable={this.onReadyMap}>
          <Map instanceRef={this.initMap} state={mapState} options={{suppressMapOpenBlock: true}} width={'100vw'} height={'100vh'}>
            {
              points.map(point => <PointComponent key={point.id} point={point} handleClick={this.handlePoint}/>)
            }
          </Map>
        </YMaps>
      </div>
    );
  }
}

export default MapComponent;

// this.mapRef.setBounds(this.mapRef.geoObjects.getBounds(), {checkZoomRange: true});
