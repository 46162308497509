import React, {Component} from 'react';

class StaticMapComponent extends Component {
  render() {
    const {point} = this.props;

    return (
      <div style={{height: '250', width: '650'}}>
        <img height="100%" width="100%" src={`https://static-maps.yandex.ru/1.x/?ll=${point.longitude},${point.latitude}&z=17&l=map&size=650,250&pt=${point.longitude},${point.latitude},pm2rdm`}/>
      </div>
    )
  }
}

export default StaticMapComponent;