import React, {Component} from 'react';
import {Placemark} from 'react-yandex-maps';

class PointComponent extends Component {
  render() {
    const {point, selected, handleClick} = this.props;

    return (
      <Placemark
        forceUpdate={{selected}}
        key={point.id}
        geometry={{
          coordinates: [point.latitude, point.longitude],
        }}
        options={{
          preset: 'islands#redCircleDotIcon',
        }}
        onClick={() => handleClick(point)}
      />
    )
  }
}

export default PointComponent;